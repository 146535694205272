import { APP_ROUTE } from '@core/constants';
import { ClientUser } from '@core/models';
import { environment } from '@environments/environment';

export const HEADER_ACCOUNT_NAV_LIST = (user: ClientUser) =>
	[
		user?.id && {
			path: APP_ROUTE.myProfile,
			data: {
				icon: 'account_circle',
				title: 'n12',
			},
		},

		!user?.id && {
			path: APP_ROUTE.placeAd,
			data: {
				icon: 'add_circle',
				title: 'n20',
			},
		},

		+user?.hasBusinessProfile && {
			path: `${APP_ROUTE.myProfile}/${APP_ROUTE.businessProfile.base}`,
			data: {
				iconImage: 'bussiness-profile',
				title: 'n2966',
			},
		},

		+user?.adsCount && {
			path: APP_ROUTE.myAdList,
			data: {
				icon: 'view_carousel',
				title: 'n5',
			},
		},

		+user?.adsCount && {
			path: APP_ROUTE.meetingSchedule.base,
			data: {
				icon: 'link',
				title: 'n3756',
				isNew: true,
			},
		},

		+user?.enquiryCount && {
			path: APP_ROUTE.myEnquiries,
			data: {
				icon: 'mark_email_read',
				title: 'n3894',
			},
		},

		user?.id && {
			path: APP_ROUTE.favorites,
			data: {
				icon: 'favorite_border',
				title: 'n3',
			},
		},
	].filter(Boolean);

export const HEADER_OTHER_NAV_LIST = [
	// TODO: Confirm in the design why these nav items are disabled.
	{
		path: APP_ROUTE.about,
		data: {
			icon: 'info',
			title: 'n58',
			isOther: true,
		},
	},

	{
		path: APP_ROUTE.terms,
		data: {
			icon: 'checklist',
			title: 'n61',
			isOther: true,
		},
	},
	{
		path: APP_ROUTE.privacyPolicy,
		data: {
			icon: 'shield',
			title: 'n62',
			isOther: true,
		},
	},
	{
		path: APP_ROUTE.cookiePolicy,
		data: {
			icon: 'cookie',
			title: 'n3124',
			isOther: true,
		},
	},

	{
		path: environment.feedbackUrl,
		data: {
			icon: 'feedback',
			title: 'n3278',
			isOther: true,
			newTab: true,
		},
	},

	{
		path: APP_ROUTE.contact,
		data: {
			icon: 'contact_support',
			title: 'n60',
			isOther: true,
		},
	},
];
